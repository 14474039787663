import 'isomorphic-unfetch'
import absoluteUrl from 'next-absolute-url'
import { getCookie, CSRFTOKEN } from './cookies'
import iconBrochure from '../../static/imgs/icon-brochure.png'
import iconEmail from '../../static/svgs/icon_email.svg'
import iconFind from '../../static/imgs/icon-find.png'
import iconEnquire from '../../static/imgs/icon-enquire.png'
import iconJoin from '../../static/imgs/icon-join.png'
import iconCalendar from '../../static/imgs/icon-calendar.png'
import { LinkAPI, MenuBlockItem, CountryCode, LAYOUT_TEMPLATE } from './types'
import { PAGE_TEMPLATE } from './constants'

interface headerProps {
  [key: string]: string
}

export function checkStatus(response: any) {
  if (!response.ok && response.statusText) {
    const error = new Error()
    error.message = response
    error.name = response.status
    throw error
  } else if (!response.ok && response.non_field_errors) {
    const error = new Error()
    error.message = response
    error.name = response.status
    throw error
  }
  return response
}

export function parseJSON(response: any) {
  return response.json().then((resJson: any) => {
    if (typeof resJson == 'string') {
      resJson = {
        data: resJson
      }
    }
    resJson.responseStatus = response.status
    resJson.ok = response.ok
    resJson.headers = response.headers
    return resJson
  })
}

export function apiCall(
  url: string,
  data?: any,
  ctx?: any,
  method = 'get',
  hasFile = false,
  token = null
) {
  if (ctx?.req) {
    // Api called from SSR
    const { protocol, host } = absoluteUrl(
      ctx.req,
      process.env.NEXT_STATIC_API_ENDPOINT
    )

    const urlPrefix =
      process.env.NEXT_STATIC_API_ENDPOINT || `${protocol}//${host}`
    url = `${urlPrefix}${url}`
  }

  process.env.NODE_ENV !== 'production' &&
    console.log('-------api called-----', url)
  let headers: headerProps = {
    'Content-Type': 'application/json'
  }
  let fetchOptions: RequestInit = {
    headers: {},
    method: method,
    credentials: 'include'
  }
  let formattedData
  const csrftoken = getCookie(CSRFTOKEN, ctx)
  if (data) {
    if (csrftoken) {
      data.csrftoken = csrftoken
    }
    if (hasFile) {
      // File uploads can't send as json - change to form
      let form_data = new FormData()
      for (let key in data) {
        form_data.append(key, data[key])
      }
      formattedData = form_data
      delete headers['Content-Type']
    } else {
      formattedData = JSON.stringify(data)
    }
  }

  if (csrftoken) {
    headers['X-CSRFToken'] = csrftoken
  }

  if (token) {
    headers['Authorization'] = `JWT ${token}`
  }
  fetchOptions.headers = headers
  if (formattedData) {
    fetchOptions.body = formattedData
  }

  const resp = fetch(url, fetchOptions)
    .then(response => {
      if (response.status === 403 || response.status === 401) {
        return response
      }
      return checkStatus(response)
    })
    .then(response => {
      let res = parseJSON(response)
      if (response.status >= 200 && response.status <= 299) {
        res.ok = true
      }

      if (response.status >= 400 && response.status <= 499) {
        res.ok = false
      }
      return res
    })
    .catch(error => {
      const response = error.message
      if (response) {
        error.status = response.status
        error.statusText = response.statusText
      }
      return error
    })

  return resp
}

export function getPanelIconSet(icon?: string) {
  switch (icon) {
    case 'email':
      return { icon: iconEmail }
    case 'brochure':
      return { icon: iconBrochure }
    case 'find':
      return { icon: iconFind }
    case 'enquire':
      return { icon: iconEnquire }
    case 'join':
      return { icon: iconJoin }
    case 'calendar':
      return { icon: iconCalendar }
  }
}

export const gymPageSubMenuItemBuild = (
  blockName:
    | 'gym_description'
    | 'gym_location'
    | 'FeaturedMembershipBenefits'
    | 'gym_personal_trainers'
    | 'ContentWithCTA'
    | 'MemberEnquiryForm'
    | string
): MenuBlockItem | undefined => {
  switch (blockName) {
    case 'gym_description':
      return {
        label: 'About Us',
        url: '#',
        blockName: 'gym_description'
      }
    case 'gym_location':
      return {
        label: 'Visit Us',
        url: '#',
        blockName: 'gym_location'
      }
    case 'FeaturedMembershipBenefits':
      return {
        label: 'Our Club',
        url: '#',
        blockName: 'FeaturedMembershipBenefits'
      }
    case 'gym_personal_trainers':
      return {
        label: 'Personal Trainers',
        url: '#',
        blockName: 'gym_personal_trainers'
      }
    case 'ContentWithCTA':
      return {
        label: 'Our Classes',
        url: '#',
        blockName: 'ContentWithCTA'
      }
    case 'MemberEnquiryForm':
      return {
        label: 'Enquire now',
        url: '#',
        blockName: 'MemberEnquiryForm'
      }
  }
}

export const performancePageSubMenuItemBuild = (
  blockName:
    | 'title_and_richtext'
    | 'performance_classes'
    | 'faq_section'
    | 'plus_performance_locations'
    | 'form'
    | 'HeroWithForm'
    | string
): MenuBlockItem | undefined => {
  switch (blockName) {
    case 'title_and_richtext':
      return {
        label: 'What is Plus Performance?',
        url: '#',
        blockName: 'title_and_richtext'
      }
    case 'performance_classes':
      return {
        label: 'Classes',
        url: '#',
        blockName: 'performance_classes'
      }
    case 'faq_section':
      return {
        label: 'FAQ',
        url: '#',
        blockName: 'faq_section'
      }
    case 'plus_performance_locations':
      return {
        label: 'Studios',
        url: '#',
        blockName: 'plus_performance_locations'
      }
    case 'form':
      return {
        label: 'Membership',
        url: '#',
        blockName: 'form'
      }
    case 'HeroWithForm':
      return {
        label: 'Own A Studio',
        url: '#',
        blockName: 'HeroWithForm'
      }
  }
}

export const getTemplate = (
  dataType: string,
  templateType?: LAYOUT_TEMPLATE
) => {
  let template = ''
  switch (templateType) {
    case PAGE_TEMPLATE.YOU_FITNESS:
      template = `${dataType}${PAGE_TEMPLATE.YOU_FITNESS}`
      break

    default:
      template = dataType
      break
  }

  return template
}

export const gymMediaPageSubMenuItemBuild = (
  blockName:
    | 'gym_description'
    | 'gym_location'
    | 'FeaturedMembershipBenefits'
    | 'gym_personal_trainers'
    | 'content'
    | 'MemberEnquiryForm'
    | string
): MenuBlockItem | undefined => {
  switch (blockName) {
    case 'gym_description':
      return {
        label: 'About Us',
        url: '#',
        blockName: 'gym_description'
      }
    case 'gym_location':
      return {
        label: 'Visit Us',
        url: '#',
        blockName: 'gym_location'
      }
    case 'FeaturedMembershipBenefits':
      return {
        label: 'Our Club',
        url: '#',
        blockName: 'FeaturedMembershipBenefits'
      }
    case 'gym_personal_trainers':
      return {
        label: 'Personal Trainers',
        url: '#',
        blockName: 'gym_personal_trainers'
      }
    case 'content':
      return {
        label: 'Classes',
        url: '#',
        blockName: 'content'
      }
    case 'MemberEnquiryForm':
      return {
        label: 'Enquire now',
        url: '#',
        blockName: 'MemberEnquiryForm'
      }
  }
}

export const GoogleMapDirectionLink = (geo: [number, number]): string => {
  return `https://www.google.com/maps?saddr=My+Location&daddr=${geo[1]},${geo[0]}`
}

export const GoogleMapPointLink = (geo: [number, number]): string => {
  return `https://www.google.com/maps/search/?api=1&query=${geo[1]},${geo[0]}`
}

export const SubMenuSerialize = (links: any): MenuBlockItem[] => {
  return links
    ? links.map(
      (link: LinkAPI): MenuBlockItem => {
        return {
          label: link.link_text,
          url:
            link.link.external_link ||
            link.link.page_link ||
            link.link.document_link,
          target: link.link.link_target
        }
      }
    )
    : []
}

export const getSiteCode = (url: string | undefined): CountryCode => {
  let siteCode: CountryCode = 'other'

  if (!url) return siteCode

  if (url.includes('.co.nz') || url.includes('nz')) siteCode = 'nz'
  // New Zealand
  else if (url.includes('.co.in') || url.includes('india')) siteCode = 'in'
  // Singapore
  else if (url.includes('.asia')) siteCode = 'asia'
  // UK
  else if (url.includes('.co.uk')) siteCode = 'uk'
  // India
  else if (url.includes('.com.au') || url.includes('.fitness')) siteCode = 'au'
  // Indonesia
  else if (url.includes('.co.id')) siteCode = 'id'
  // Australia
  else siteCode = 'other' // Others

  return siteCode
}


export const getCountryFromCode = (code: string | undefined): string => {
  // In the event we want to print a readable address from the code
  let country = ""

  switch (code) {
    case "au":
      country = "Australia";
      break;
    case "nz":
      country = "New Zealand";
      break;
    case "in":
      country = "India";
      break;
    case "id":
      country = "Indonesia";
      break;
    case "asia":
      country = "Asia";
      break;
    case "UK":
      country = "UK";
      break;
  }
  return country
}
